






import Vue from "vue";
import DataNotFound from "@/components/shared/DataNotFound.vue";

export default Vue.extend({
  name: "ErrorPage",
  components: { DataNotFound }
});
